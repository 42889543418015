<nav
  [@animateMenu]
  class="navbar main-navbar on-top"
  [class.nav-shadow]="this.pageYPosition > 0"
>
  <div class="container">
    <a class="navbar-brand text-light" [routerLink]="'inicio'">
      <img src="assets/images/me/logo.png" width="75" />
    </a>
    <ul ngbNav #nav="ngbNav" class="menu-ul">
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent('click_about', 'menu', 'click')
        "
      >
        <a ngbNavLink (click)="scroll('about')"
          ><span class="nav-number">01. </span>
          <span class="underline nav-text">
            {{ "Header.Item1" | translate }}</span
          ></a
        >
        <ng-template ngbNavContent>First content</ng-template>
      </li>
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent(
            'click_experience',
            'menu',
            'click'
          )
        "
      >
        <a ngbNavLink (click)="scroll('jobs')"
          ><span class="nav-number">02. </span>
          <span class="underline nav-text">
            {{ "Header.Item2" | translate }}</span
          ></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent('click_jobs', 'menu', 'click')
        "
      >
        <a ngbNavLink (click)="scroll('proyects')"
          ><span class="nav-number">03. </span>
          <span class="underline nav-text">
            {{ "Header.Item3" | translate }}</span
          ></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent('click_contact', 'menu', 'click')
        "
      >
        <a ngbNavLink (click)="scroll('contact')"
          ><span class="nav-number">04. </span>
          <span class="underline nav-text">
            {{ "Header.Item4" | translate }}</span
          ></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="downloadCV()" class="main-btn cv-btn" ngbNavLink>
          {{ "Header.cvBtn" | translate }}
        </a>
      </li>
    </ul>
    <div class="menu-wrapper">
      <div
        [class.animate]="responsiveMenuVisible"
        (click)="responsiveMenuVisible = !responsiveMenuVisible"
        class="hamburger-menu"
      ></div>
    </div>
    <div
      class="menu-responsive"
      [ngStyle]="{ 'pointer-events': !responsiveMenuVisible ? 'none' : '' }"
    >
      <aside
        [class.aside-show]="responsiveMenuVisible"
        [class.nav-shadow]="this.pageYPosition > 0"
        class="on-top"
      >
        <nav>
          <ol>
            <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_about',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('about')">
                <span>01. </span> {{ "Header.Item1" | translate }}
              </a>
            </li>
            <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_experience',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('jobs')">
                <span>02. </span> {{ "Header.Item2" | translate }}
              </a>
            </li>
            <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_jobs',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('proyects')">
                <span>03. </span> {{ "Header.Item3" | translate }}
              </a>
            </li>
            <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_contact',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('proyects')">
                <span>04. </span> {{ "Header.Item4" | translate }}
              </a>
            </li>
            <li>
              <a (click)="downloadCV()" class="main-btn cv-btn">
                {{ "Header.cvBtn" | translate }}
              </a>
            </li>

            <!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->
          </ol>
        </nav>
      </aside>
    </div>
  </div>
</nav>
